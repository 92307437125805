import React from 'react'

const Spinner = () => {
  return (
    <div className="blocks">
      <div className="block orange" />
      <div className="block blue" />
    </div>
  )
}

export default Spinner
