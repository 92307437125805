import React, { useContext } from 'react'
import { UserContext } from '../providers/UserProvider'
import { auth, functions } from '../firebase'

const AuthSignedPage = props => {
  const { user } = useContext(UserContext)
  const { email } = user
  console.log(user)

  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="border text-center  bg-blue-200 border-blue-400 px-3 py-4 rounded">
        <h1 className="text-3xl font-bold">Atios</h1>
        <p>By signing in, you are authorizing Google to access your devices.</p>
      </div>

      <div>
        <button
          className="w-full py-3 bg-green-600 hover:bg-green-700 active:bg-green-800 mt-4 text-white rounded"
          onClick={() => {
            const authorize = functions.httpsCallable('oauth-Authorize')
            console.log(props.location)
            authorize(props.location.search).then(authResponse => {
              console.info(`got response ${JSON.stringify(authResponse)}`)
              window.location.href = `${authResponse.data.authRequest.redirect_uri}?code=${authResponse.data.authCode}&state=${authResponse.data.authRequest.state}`
            })
          }}
        >
          Сontinue as {email}
        </button>
      </div>
      <button
        className="w-full py-3 bg-red-600  hover:bg-red-700 active:bg-red-800 mt-4 text-white rounded"
        onClick={() => {
          auth.signOut()
        }}
      >
        Sign out
      </button>
    </div>
  )
}

export default AuthSignedPage
