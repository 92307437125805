import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/database'

// // dev
// firebase.initializeApp({
//   apiKey: 'AIzaSyCsdWZEbp00d98L_fG4ubWnTnti-7P91g4',
//   authDomain: 'atios-esp32-iot.firebaseapp.com',
//   databaseURL: 'https://atios-esp32-iot-default-rtdb.europe-west1.firebasedatabase.app/',
//   projectId: 'atios-esp32-iot',
//   storageBucket: 'atios-esp32-iot.appspot.com',
//   messagingSenderId: '112154854868',
//   appId: '1:112154854868:web:3dbee3c8f82039b4890241'
// });

// prod
firebase.initializeApp({
  apiKey: 'AIzaSyCSAFGHuKWhyGjaMyBvWA4ZrGnya4L9Pzg',
  authDomain: 'prod-atios-iot-d3826.firebaseapp.com',
  databaseURL: 'https://prod-atios-iot-d3826-default-rtdb.firebaseio.com',
  projectId: 'prod-atios-iot-d3826',
  storageBucket: 'prod-atios-iot-d3826.appspot.com',
  messagingSenderId: '565748077231',
  appId: '1:565748077231:web:f7567ae756ec007bf8fa5a',
  measurementId: 'G-ZBJQM6GNZ3',
})

firebase.analytics()

export const auth = firebase.auth()
export const storage = firebase.storage().ref()
export const performance = firebase.performance()
export const firestore = firebase.firestore()
export const functions = firebase.functions()
export const database = firebase.database()

const provider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider)
}

const getUserDocument = async uid => {
  if (!uid) return null
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get()

    return {
      uid,
      ...userDocument.data(),
    }
  } catch (error) {
    console.error('Error fetching user', error)
  }
}

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return

  const userRef = firestore.doc(`users/${user.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData,
      })
    } catch (error) {
      console.error('Error creating user document', error)
    }
  }
  return getUserDocument(user.uid)
}

export default firebase
