import React, { Component, createContext } from 'react'
import { auth, generateUserDocument } from '../firebase'

export const UserContext = createContext({ user: null })

class UserProvider extends Component {
  state = {
    triedAuth: false,
    user: null,
  }

  componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      const user = await generateUserDocument(userAuth)
      this.setState({ user, triedAuth: true })
    })
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider
