import React, { useContext, useState } from 'react'
import { useAlert } from 'react-alert'
import { UserContext } from '../providers/UserProvider'
import { auth, functions, database } from '../firebase'

function deviceComponent(deviceId, data) {
  return (
    <tr key={deviceId}>
      <td>{deviceId}</td>
      <td>{data.channels}</td>
      <td>{data.traits.join(', ')}</td>
    </tr>
  )
}

const ProfilePage = () => {
  const alert = useAlert()
  const { user } = useContext(UserContext)
  const { photoURL, displayName, email } = user
  console.log(user)

  const [devices, setDevices] = useState(undefined)

  const generateCode = () => {
    const callableReturnMessage = functions.httpsCallable(
      'deviceManagment-generateCode',
    )

    callableReturnMessage()
      .then(result => {
        console.log(result.data.output)
        alert.show(result.data.output, {
          title: 'Device Activation Code',
        })
      })
      .catch(error => {
        console.log(`error: ${JSON.stringify(error)}`)
      })
  }

  const removeAllDevices = () => {
    const callableReturnMessage = functions.httpsCallable(
      'deviceManagment-removeAllDevices',
    )
    callableReturnMessage()
      .then(result => {
        console.log(result.data.output)
        alert.show(`${result.data.output} devices were removed!`)
      })
      .catch(error => {
        console.log(`error: ${JSON.stringify(error)}`)
      })
  }

  if (devices === undefined) {
    database
      .ref(`users/${auth.currentUser.uid}/devices`)
      .once('value', snapshot => {
        const fetchedDevices = []
        snapshot.forEach(data => {
          const dataVal = data.val()
          console.log(dataVal)
          fetchedDevices.push(deviceComponent(data.key, dataVal))
        })
        setDevices(fetchedDevices)
      })
  }

  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
      <div className="flex border flex-col items-center md:flex-row md:items-start border-blue-400 px-3 py-4">
        <div
          style={{
            background: `url(${photoURL ||
              'https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png'})  no-repeat center center`,
            backgroundSize: 'cover',
            height: '200px',
            width: '200px',
          }}
          className="border border-blue-300"
        />

        <div className="md:pl-4">
          <h2 className="text-2xl font-semibold">{displayName}</h2>
          <h3 className="italic">{email}</h3>
        </div>
      </div>

      {devices ? (
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
          }}
        >
          {/* <h2>Devices: {devices.length}</h2> */}
          <table
            className="table"
            border="2"
            style={{
              width: '100%',
            }}
          >
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Channels</th>
                <th scope="col">Traits</th>
              </tr>
            </thead>
            <tbody>{devices}</tbody>
          </table>
        </div>
      ) : null}

      <div>
        <button
          className="w-full py-3 bg-blue-600  hover:bg-blue-700 active:bg-blue-800 mt-4 text-white rounded"
          onClick={event => removeAllDevices(event)}
        >
          Remove all the devices
        </button>
      </div>

      <div>
        <button
          className="w-full py-3 bg-gray-500  hover:bg-grey-600 active:bg-grey-800  mt-4 text-white rounded"
          onClick={event => generateCode(event)}
        >
          Add Device
        </button>
      </div>
      <button
        className="w-full py-3 bg-red-600 hover:bg-red-700 active:bg-red-800  mt-4 text-white rounded "
        onClick={() => {
          auth.signOut()
        }}
      >
        Sign out
      </button>
    </div>
  )
}

export default ProfilePage
