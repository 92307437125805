import React from 'react'
import * as queryString from 'query-string'
import firebase, { auth } from '../firebase'

class SignIn extends React.Component {
  render() {
    return (
      <div className="mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8">
        <div className="border text-center  bg-blue-200 border-blue-400 px-3 py-4 rounded">
          <h1 className="text-3xl font-bold">Atios</h1>
          <p>
            By signing in, you are authorizing Google to access your devices.
          </p>
        </div>
        <h3 className="text-2xl mb-2 text-center font-bold">Sign In</h3>

        <div className="border border-blue-400 mx-auto  rounded py-8 px-4 md:px-8">
          {/* {error !== null && <div className="py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>} */}

          <button
            className="bg-red-500 hover:bg-red-600 active:bg-red-700 w-full py-2 text-white rounded"
            onClick={() => {
              const queryParams = queryString.parse(this.props.location.search)
              console.info('parsed data: ', JSON.stringify(queryParams))
              auth
                .signInWithRedirect(new firebase.auth.GoogleAuthProvider())
                .then(() => {
                  console.log('signed In time to send tokens to skill')
                })
            }}
          >
            Sign in with Google
          </button>
        </div>
      </div>
    )
  }
}

export default SignIn
