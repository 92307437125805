import React, { useContext } from 'react'
import { Router } from '@reach/router'
import SignIn from './SignIn'
import Auth from './Auth'
import Spinner from './Spinner'
import AuthSigned from './AuthSignedIn'
import ProfilePage from './ProfilePage'
// import Privacy from './Privacy'
import { UserContext } from '../providers/UserProvider'

function Application() {
  const { user, triedAuth } = useContext(UserContext)
  console.log('user data', user, triedAuth)
  if (!triedAuth) {
    return <Spinner />
  }
  if (user) {
    return (
      <Router>
        <ProfilePage path="/" />
        <AuthSigned path="/auth" user />
        {/* <Privacy path="/privacy" /> */}
      </Router>
    )
  }
  return (
    <Router>
      <SignIn path="/" />
      <Auth path="/auth" user />
      {/* <Privacy path="/privacy" /> */}
    </Router>
  )
}

export default Application
