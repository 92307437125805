import React from 'react'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-mui'
import Application from './Components/Application'
import UserProvider from './providers/UserProvider'

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  // timeout: 5000,
  offset: '50px',

  // you can also just use 'scale'
  transition: transitions.FADE,
}

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <UserProvider>
        <Application />
      </UserProvider>
    </AlertProvider>
  )
}

export default App
